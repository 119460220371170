// import { captureRemixErrorBoundaryError } from '@sentry/remix';
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useRouteError,
} from '@remix-run/react';
import './components/globalstyles.css';
import polarisStyles from "@shopify/polaris/build/esm/styles.css?url";  
import { LoaderFunctionArgs } from '@remix-run/cloudflare';

export const links = () => [{ rel: "stylesheet", href: polarisStyles }];

export const ErrorBoundary = () => {
  const error = useRouteError();
  // captureRemixErrorBoundaryError(error);
  return <div>Something went wrong {JSON.stringify(error)}</div>;
  // return <div>Something went wrong</div>;
};

export async function loader({ context }: LoaderFunctionArgs) {
  return {
    ENV: {
      SENTRY_DSN: context.cloudflare.env.SENTRY_DSN,
      ENVIRONMENT: context.cloudflare.env.ENVIRONMENT,
    }
  };
}

export default function App() {
  const data = useLoaderData<typeof loader>();
  return (
    <html lang='en'>
      <head>
        <meta charSet='utf-8' />
        <meta name='viewport' content='width=device-width,initial-scale=1' />
        <meta name="shopify-debug" content="web-vitals" />
        <link rel='preconnect' href='https://cdn.shopify.com/' />
        <link
          rel='stylesheet'
          href='https://cdn.shopify.com/static/fonts/inter/v4/styles.css'
        />
        <Meta />
        <Links />
      </head>
      <body>
        <script
          dangerouslySetInnerHTML={{
            __html: `window.ENV = ${JSON.stringify(data.ENV)}`,
          }}
        />
        <Outlet />
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}